<template>
   <v-row>
    <v-col cols="12">
      <v-toolbar color="transparent mt-1" flat height="48">
        <v-toolbar-title class="ml-n3 subtitle-1">
          POS
          <v-icon class="mx-1" color="secondary" size="22">mdi-chevron-right</v-icon>
          <span class="text-decoration-none secondary--text font-weight-bold">Actividad de tiendas</span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-query-builder class="mr-2" :query-map="filters" model="branches" />
        </v-toolbar-items>
      </v-toolbar>
    </v-col>

    <v-col cols="12">
      <template v-if="loading">
        <v-skeleton-loader transition="fade-transition" type="list-item" width="270" />
        <v-skeleton-loader class="mt-n4" transition="fade-transition" type="list-item" width="150" />
      </template>
      <branches-activity-list :loading="loading" />
      <!-- <v-card class="px-0 mt-0" flat>
        <template v-if="loading">
          <v-skeleton-loader transition="fade-transition" type="list-item" width="270" />
          <v-skeleton-loader class="mt-n4" transition="fade-transition" type="list-item" width="150" />
        </template>
        <v-card-text class="px-0 py-0">
          <branches-activity-list :loading="loading" />
        </v-card-text>
      </v-card> -->
    </v-col>
  </v-row>
</template>
<script>
import BranchesActivityList from '@/modules/pos/branch/components/BranchesActivityList'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import ListViewMixin from '@/mixins/ListViewMixin'
export default {
  components: {
    BranchesActivityList,
    VQueryBuilder
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    filters: [
      {
        type: 'text',
        id: 'supplier__name',
        label: 'Proveedor',
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'date',
        label: 'Fecha',
        operators: ['es igual', 'es menor a', 'es mayor a']
      },
      {
        type: 'numeric',
        id: 'total',
        label: 'Total',
        operators: ['es igual', 'es menor a', 'es mayor a']
      },
      {
        type: 'numeric',
        id: 'number',
        label: 'Número',
        operators: ['es igual', 'es menor a', 'es mayor a']
      }
    ],
    loading: false
  }),
  methods: {
    async getList () {
      if (!this.list.length) {
        this.loading = true
        await this.$store.dispatch('purchases/LIST', {
          resource: 'purchases',
          query: this.$route.query
        })
        this.loading = false
      }
    }
  }
}
</script>