<template>
  <v-row no-gutters>
    <v-col cols="12">
      <skeleton-list v-if="loading" />
      <template v-else>
        <v-empty-state v-if="!branchesActivityList.length" title="No existen actividades" type="branches" />
        <template v-else>
          <v-data-table class="tableList" :headers="headers" :items="branchesActivityList" :items-per-page="pagination" hide-default-header disable-sort mobile-breakpoint hide-default-footer @click:row="(branchesActivitylist) => { hasLink ? $router.push({name: 'BranchRetrieveUpdate', params: {id: 'bra_dsadr8485jfs6dasuyrt'}}).catch(() => {}) : ''}" style="cursor: pointer">
            <!-- header -->
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders :headers="headers" @sort="setSort" />
            </template>
            <!-- end header -->
            <template v-slot:[`item.name`]="{item}">
              <v-btn class="body-2 primary--text font-weight-bold px-2" :to="{ name: 'BranchRetrieveUpdate', params: {id: item.branch_id, ...$route.params} }" text>
                {{item.name}}<v-icon color="primary" right size="18">mdi-arrow-right</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.percentageActivity`]="{item}">
              <div class="text-right">
                <v-progress-linear v-model="item.percentageActivity" color="primary" height="18" rounded>
                  <template v-slot:default="{value}">
                    <span class="white--text body-2 font-weight-medium">{{value}}%</span>
                  </template>
                </v-progress-linear>
              </div>
            </template>
          </v-data-table>
          <template v-if="showPagination || showAll">
            <v-row class="mx-0" align="center">
              <v-col cols="12" class="text-center" v-if="showAll">
                <v-btn :to="{name: 'BranchesActivityList', query: showCriteria, params: $route.params}" outlined color="secondary">Ver todas las compras</v-btn>
              </v-col>
              <template v-if="showPagination">
                <v-col cols="6">
                  <span class="body-2 text--secondary">{{count}} registros</span>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-pagination v-model="page" :length="totalPages" total-visible="6" />
                </v-col>
              </template>
            </v-row>
          </template>
        </template>
      </template>
      <!-- dialog pdf -->
      <v-dialog v-model="modalRender" width="700" persistent no-click-animation overlay-color="overlay">
        <render-pdf :documentType="current.document_type | nameDocumentsType" :number="current.number" :loaderRender="loaderRender" :renderUrl="renderUrl" @close="modalRender=false" :key="current.number" />
      </v-dialog>
      <!-- end dialog pdf -->
    </v-col>
    <router-view />
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VEmptyState from '@/components/commons/VEmptyState'
import RenderPdf from '@/components/files/RenderPdf'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import ListViewMixin from '@/mixins/ListViewMixin'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import RenderViewMixin from '@/mixins/RenderViewMixin'
import ChipStatus from '@/components/commons/ChipStatus'

export default {
  components: {
    ChipStatus,
    RenderPdf,
    VEmptyState,
    SkeletonList,
    VTableHeaders
  },
  mixins: [
    ListViewMixin,
    GenericViewMixin,
    RenderViewMixin
  ],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    headers: [
      { text: 'Tienda', value: 'name', sortable: true },
      { text: '# POS activos', value: 'activePos', align: 'end', sortable: true },
      { text: '# POS inactivos', value: 'inactivePos', align: 'end', sortable: true },
      { text: 'Porcentaje de actividad ', value: 'percentageActivity', align: 'end', width: 350, sortable: true }
    ],
    branchesActivityList: [
      {
        idBranch: 'bra_dsadr8485jfs6dasuyrt',
        name: 'Casa Matriz',
        activePos: '2',
        inactivePos: '8',
        percentageActivity: '20'
      },
       {
        idBranch: 'bra_dsadr8485jfs6dasuyrt',
        name: 'Coquimbo Centro',
        activePos: '5',
        inactivePos: '10',
        percentageActivity: '50'
      },
       {
        idBranch: 'bra_dsadr8485jfs6dasuyrt',
        name: 'Unimarc',
        activePos: '9',
        inactivePos: '10',
        percentageActivity: '90'
      }
    ],
    current: {}
  }),
  computed: mapState({
    count: state => state.branches.branchesCount
  })
}
</script>